/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.table-import .import-new {
  display: inline-block;
  color: #fff;
  background-color: #52c41a;
  font-size: 0.6em;
  width: 16px;
  height: 16px;
  vertical-align: top;
  text-align: center;
  padding-top: 2px;
  margin-top: 0.5px;
  border-radius: 50%;
}
